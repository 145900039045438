<template>
  <div class="p-6 pr-6 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          Commerces / Entreprises ({{ stat }})
        </div>

        <div class="w-1/2 flex justify-end">
          <div class="w-1/2">
            <inputo :icon="icons.search" @info="retourSearch" height="50px" :searchMode="true" placeholder="Rechercher..." />
          </div>

          <div class="w-1/3 ml-4">
            <multiselect :option="allCategorie" value-t="Catégorie" height="50px" sizeDefil="13px" @info="backFiltre"/>
          </div>

          <div class="w-1/3 ml-4" v-if="admin.userType === 'ADMIN'">
            <bouton
                v-if="allUser.length === 0"
                label="Exporter"
                border="1px solid white"
                background="#C4C4C4"
                color="#fff"
                size="14px"
                :charge="true"
            />
            <excel :donne="allUser" v-if="allUser.length > 0"/>
          </div>

        </div>
      </div>

      <div class="mt-6">
        <global-view
          :search="recherche"
          :filtrage="valueCategorie"
          @filt="filtrage = null"
          @stat="backStat"
          @user="backUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../../assets/icons/search.svg'
import globalView from '../../component/cdevs/commerce/globalData'
import inputo from '../../component/helper/form/input'
import multiselect from '../../component/helper/form/multiselect'
import excel from '../../component/cdevs/commerce/excelCommerce'
import bouton from '../../component/helper/add/button'

export default {
  name: "Index",

  components: {
    globalView,
    inputo,
    multiselect,
    excel,
    bouton
  },

  data () {
    return {
      icons: {
        search
      },
      allUser: [],
      recherche: null,
      valueCategorie: null,
      stat: '',
      allCategorie: ['TOUT', 'AGRO ALIMENTAIRE', 'BANQUE/ASSURANCE', 'BOUTIQUE', 'COMMERCE', 'COMMUNICATION/MARKETING', 'DIGITAL', 'EDUCATION', 'TRANSPORT', 'RESTAURATION', 'SANTÉ', 'SERVICES AUX ENTREPRISES', 'AUTRES'],
      admin: null
    }
  },

  created () {
    this.admin = this.$store.getters.trackUser.user
  },

  methods: {
    backStat (a) {
      this.stat = a
    },

    retourSearch (answer) {
      this.recherche = answer
    },

    backFiltre(s) {
      if (s !== 'Catégorie'){
        this.valueCategorie = s
      }
    },

    backUser (answer) {
      this.allUser = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
